/**
 * @description Component-Footer
 */
import React, { FC } from 'react';
import './index.less';
import { Default, Mobile, useMediaQueryDevice } from '@/components/MediaQuery';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
interface IPropTypes {
  className?: string;
}
const Footer: FC<IPropTypes> = function () {
  const { deviceType } = useMediaQueryDevice();
  return (
    <div className={`cor-footer ${deviceType}`}>
      <Default>
        <div className="cor-footer-inner">
          <div className="cor-footer-copyright">
            <Trans i18nKey="landing.footer.copyRight" />{' '}
          </div>
          <div className="cor-footer-links">
            <Link to="/privacy">
              <Trans i18nKey="landing.footer.pp" />
            </Link>
            <Link to="/terms">
              <Trans i18nKey="landing.footer.tos" />
            </Link>
            <Link to="/limited-use-disclosure">
              <Trans i18nKey="landing.footer.lud" />
            </Link>
          </div>
        </div>
      </Default>
      <Mobile>
        <>
          <div className="cor-footer-copyright">
            <Trans i18nKey="landing.footer.copyRightMobile" />
          </div>
          <div className="cor-footer-links">
            <Link to="/privacy-policy">
              <Trans i18nKey="landing.footer.privacy" />
            </Link>
            <Link to="/terms-of-service">
              <Trans i18nKey="landing.footer.terms" />
            </Link>
            <Link to="/limited-use-disclosure">
              <Trans i18nKey="landing.footer.disclosure" />
            </Link>
          </div>
        </>
      </Mobile>
    </div>
  );
};

export default Footer;
