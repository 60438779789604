/**
 * @description Component-Header
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { Button, Menu, MenuProps } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderDropDown from '@/pages/components/HeaderDropDown';
import Logo from '@/pages/components/Logo';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import { useTranslation } from 'react-i18next';
interface IPropTypes {
  className?: string;
  isDisclosure?: boolean;
}
const Header: FC<IPropTypes> = function ({}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { deviceType } = useMediaQueryDevice();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const login = useCallback(async () => {
    try {
      await loginWithRedirect();
    } catch (e) {}
  }, [loginWithRedirect]);
  // const scrollToProducts = useCallback(() => {
  //   const productEl = document.getElementById('products');
  //   if (productEl) {
  //     productEl.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, []);
  const menuItems = [
    {
      key: 'company',
      label: 'Company',
      children: [
        { key: 'about', label: 'About' },
        { key: 'career', label: 'Careers' },
      ],
    },
  ];
  const onClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      navigate(`/${e.key}`);
    },
    [navigate],
  );
  return (
    <div className={`cor-header ${deviceType}`}>
      <div className="cor-header-left">
        <Logo />
        <div className="cor-header-left-btns">
          <Button
            onClick={() => {
              navigate('/about');
            }}
            type="text">
            {t('landing.header.aboutUs')}
          </Button>
          <Button
            onClick={() => {
              navigate('/career');
            }}
            type="text">
            {t('landing.header.careers')}
          </Button>
          <Button
            onClick={() => {
              navigate('/privacy');
            }}
            type="text">
            {t('landing.header.privacy')}
          </Button>
          <Button
            onClick={() => {
              navigate('/terms');
            }}
            type="text">
            {t('landing.header.terms')}
          </Button>
        </div>
      </div>
      <div className="cor-header-btns">
        {/* {org && (
          <Button
            onClick={() => {
              navigate('/livedemo');
            }}
            type="text">
            {t('landing.header.liveDemo')}
          </Button>
        )} */}
        {isAuthenticated ? (
          <>
            {!location.pathname.includes('dashboard') && (
              <Button
                onClick={() => {
                  navigate('/dashboard');
                }}
                type="text">
                {t('landing.header.dashboard')}
              </Button>
            )}
            <HeaderDropDown />
          </>
        ) : (
          <>
            <Button type="ghost" onClick={login}>
              {t('landing.header.login')}
            </Button>
            <Button type="primary" onClick={login}>
              {t('landing.header.signUp')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
