/**
 * @description Component-About
 */
import React, { FC } from 'react';
import './index.less';
import Footer from '../Landing/Footer';
import Header from '../Landing/Header';
import HeaderMobile from '../LandingMobile/Header';
import FooterMobile from '../LandingMobile/Footer';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const Terms: FC<IPropTypes> = function (props) {
  const { deviceType, isMobile } = useMediaQueryDevice();
  return (
    <div className={classNames('cor-terms', deviceType)}>
      {isMobile ? <HeaderMobile /> : <Header />}
      <div className="cor-terms-content">
        <h1>
          <strong>Terms of Service</strong>
        </h1>
        <p>Last Updated: 09/09/2023</p>
        <h2>
          <strong>1. Introduction</strong>
        </h2>
        <p>
          Welcome to Cornerstone.Chat, a service provided by Hermon Consulting LLC (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; &quot;our&quot;). Our
          registered office address is at 9407 NE Vancouver Mall Dr Ste 104 # 1209, Vancouver, WA, 98662-6191, United
          States. These Terms of Service (&quot;Terms&quot;) govern your use of the Cornerstone.Chat website, software, and any
          other services provided by us (collectively, the &quot;Service&quot;】).
        </p>
        <p>
          By accessing or using our Service, you agree to comply with and be bound by these Terms and our Privacy
          Policy. If you do not agree with these Terms or our Privacy Policy, please do not use the Service.
        </p>
        <h2>
          <strong>2. Account Creation and Management</strong>
        </h2>
        <h3>
          <strong>2.1 Registration</strong>
        </h3>
        <p>
          To use certain features of the Service, you may need to register for an account. During the registration
          process, you must provide accurate, complete, and up-to-date information. Failure to do so constitutes a
          breach of these Terms.
        </p>
        <h3>
          <strong>2.2 Account Security</strong>
        </h3>
        <p>
          You are responsible for maintaining the confidentiality of your account and password, including but not
          limited to the restriction of access to your computer and/or account. You agree to accept responsibility for
          any and all activities or actions that occur under your account.
        </p>
        <h2>
          <strong>3. Content</strong>
        </h2>
        <h3>
          <strong>3.1 User-Generated Content</strong>
        </h3>
        <p>
          Users may create and/or upload content, such as text, images, videos, and other forms of data or communication
          (&quot;User Content&quot;). You represent that you own or have the necessary permissions to use and authorize the use of
          User Content you create or upload.
        </p>
        <h3>
          <strong>3.2 Intellectual Property Rights</strong>
        </h3>
        <p>
          All intellectual property in the Service, including but not limited to logos, design elements, text, and
          graphics, are owned by or licensed to Hermon Consulting LLC and are protected by law. Unauthorized use of this
          intellectual property is prohibited.
        </p>
        <h2>
          <strong>4. Subscription Plans and Billing</strong>
        </h2>
        <h3>
          <strong>4.1 Subscription Plans</strong>
        </h3>
        <p>
          We offer various subscription plans for our Service. Details of these plans, including the features and
          pricing, can be found on the Service&apos;s website.
        </p>
        <h3>
          <strong>4.2 Billing</strong>
        </h3>
        <p>
          All subscription plans are billed in advance and are non-refundable, except as otherwise stated in our Refund
          Policy.
        </p>
        <h2>
          <strong>5. Feedback and Suggestions</strong>
        </h2>
        <p>
          If you provide us with feedback or suggestions about the Service (&quot;Feedback&quot;), you hereby grant us an
          unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to use the Feedback in any
          manner and for any purpose, including to improve the Service and create other products and services.
        </p>
        <h2>
          <strong>6. Promotions, Contests, and Sweepstakes</strong>
        </h2>
        <p>
          We may offer promotions, contests, and sweepstakes (&quot;Promotions&quot;) which may be governed by separate rules. If
          you participate in Promotions, please review the applicable rules and our Privacy Policy. If the rules for a
          Promotion conflict with these Terms, the Promotion rules will govern.
        </p>
        <h2>
          <strong>7. Contact Us</strong>
        </h2>
        <p>
          For any questions about these Terms or the Service, please contact us by email at
          <a href="mailto:tom@cornerstone.chat">tom@cornerstone.chat</a>.
        </p>
        <h2>
          <strong>8. Changes to These Terms</strong>
        </h2>
        <p>
          We reserve the right to modify or replace these Terms at any time. If a revision is material, we will make
          reasonable efforts to provide at least 30 days&apos; notice prior to any new terms taking effect.
        </p>
        <h2>
          <strong>9. Governing Law</strong>
        </h2>
        <p>
          These Terms shall be governed by and defined following the laws of the State of Washington, United States.
          Hermon Consulting LLC and yourself irrevocably consent that the courts of the State of Washington shall have
          exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
        </p>
      </div>
      {isMobile ? <FooterMobile /> : <Footer />}
    </div>
  );
};

export default Terms;
